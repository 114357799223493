'use strict';
require('raf/polyfill');

// const Enzyme = require('enzyme');
// const Adapter = require('enzyme-adapter-react-16');
//
// Enzyme.configure({ adapter: new Adapter() });
if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

// IE11 doesn't support Array.includes
require('core-js/features/array/includes');

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
  require('raf').polyfill(global);
}
//VO-10611 Web SSO: CAUI is not loaded in IE
require('string.prototype.startswith');
require('string.prototype.endswith');
require('string.prototype.includes');
require("core-js/es/symbol");
require("core-js/features/symbol/iterator.js");